<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1" style="padding-bottom: 48px;">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Select Carrier
                      <span>*</span>
                    </label>
                     <select v-model="postaldocument.provider_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Carrier </option>
                      <option v-for="(carrier,i) in carriers" :key="'carriers'+i" :value="carrier.id">{{carrier.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>

                <td>
                  <ValidationProvider name="postal_document_type" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                    <label>
                      Document Type
                      <span>*</span>
                    </label>
                    <input type="text" v-model="postaldocument.postal_document_type" placeholder="Type Character limit 30" :class="errors[0] ? 'warning-border':''" required="required" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
              </td>
            </tr>

            <tr>
              <td>
                <ValidationProvider name="postal_document_name" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Document Name
                      <span>*</span>
                    </label>
                    <input type="text" v-model="postaldocument.postal_document_name" placeholder="Name Character limit 30" :class="errors[0] ? 'warning-border':''" required="required" :maxlength="max"/>
                  </div>
                </ValidationProvider>
              </td>

                <td>
                  <ValidationProvider name="costcenter" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Select Cost Centre
                      <span>*</span>
                    </label>
                    <select v-model="postaldocument.cost_center_id" :class="errors[0] ? 'warning-border':''">
                      <option value="" disabled>Select Cost Centre </option>
                      <option v-for="(costcenter,i) in costcenters" :key="'costcenter'+i" :value="costcenter.id">{{costcenter.name}}</option>
                    </select>
                  </div>
                  </ValidationProvider>
                </td>
            </tr>

            <tr>
                <td>
                  <ValidationProvider name="unitprice" rules="required" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                     Unit Price
                      <span>*</span>
                    </label>
                      <input type="text" v-model="postaldocument.unit_price" placeholder="Enter unit price" :class="errors[0] ? 'warning-border':''" required="required" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
                </td>

                <td>
                  <ValidationProvider name="totalqty" rules="required|max:30" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                    <label>
                     Total Quantity On Hand
                      <span>*</span>
                    </label>
                      <input type="text" v-model="postaldocument.total_qty" placeholder="enter Total Quantity On Hand" :class="errors[0] ? 'warning-border':''" required="required" :maxlength="max"/>
                  </div>
                  </ValidationProvider>
              </td>
            </tr>

            <tr>
              <td>

              <div class="label_and_element_wrapper" >
                <label>
                Markup Margin 
                <span>*</span>
                </label>
                
                </div>

                <div class="newdiv" style="margin-left: 16px;margin-top: -10px;position: absolute;">
                <label class="radio-inline">
                Percentage
                </label> 
                <input type="radio" name="optradio"  @change="onChangeMarkupMargin($event)" checked="checked" value="0" style="margin: 0 15px;position: relative;"> 

                 <input type="text" placeholder="Enter" v-model="postaldocument.markup_margin_per" :disabled="postaldocument.markup_margin_type!='per'" style="width: 20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">

                <label class="radio-inline" style="margin: 0 0 0 15px;">
                Fixed
                </label> 
                <input type="radio" name="optradio"  @change="onChangeMarkupMargin($event)" value="1" style="margin: 0 15px;position: relative;"> 
                <input type="text" placeholder="Enter" v-model="postaldocument.markup_margin_fix" :disabled="postaldocument.markup_margin_type!='fix'"  style="width:20%;height: 10px;padding: 5px 20px;height: 40px;background: #fff;border: 1px solid #ccc;">
                </div>
              </td>
            </tr>

            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PostalDocumentType",
  data(){
    return {
      max:30,
      carriers:[],
      costcenters:[],
      postaldocument:{
        type:"",
        name : "",
        provider_id:"",
        cost_center_id :"",
        unit_price:"",
        total_qty:"",
        markup_margin:0,
        markup_margin_type:"per",
       markup_margin_per:0,
       markup_margin_fix:""
      }
    }
  },
  created() {
    this.fetchCarriers();
    this.fetchCostCenter();
  
  },
  methods: {
     fetchCarriers(){
      this.axios.get("/api/provider")
      .then(response => {
          this.carriers = response.data.providers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },

      fetchCostCenter(){
      this.axios.get("/api/cost")
      .then(response => {
          this.costcenters = response.data.cost_centers;
      })
      .catch(error =>{
          this.toast.error();
      });
    },
    onChangeMarkupMargin(event) {

    //console.log(optionText);
     var optionText = event.target.value;
      if(optionText == 0)
      {
        this.postaldocument.markup_margin_type ="per";
        this.postaldocument.markup_margin_fix ="";
        
      }
      if(optionText == 1)
      {
        this.postaldocument.markup_margin_type ="fix";
        this.postaldocument.markup_margin_per ="";
      }

    },
    submit(){
    
      this.postaldocument.markup_margin = this.postaldocument.markup_margin_per != ''? this.postaldocument.markup_margin_per : this.postaldocument.markup_margin_fix;
      if(this.postaldocument.markup_margin=='')
        this.postaldocument.markup_margin=0;
      this.$refs.observer.validate();
      this.axios.post("/api/postaldocument",this.postaldocument)
        .then(response => {
          this.toast.success(response.data.msg);
          this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0;
}
.error-highlight{
  color:red;
}


._sur_newdiv label{padding-top: 12px;}
._sur_flex_boxes input[type="checkbox"]{
  display: inline-block;
width: 20px;
height: 20px;
border: 1px solid #333;
position: relative;
top: 2px;
}


</style>